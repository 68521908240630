import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';
import type { TablePanel } from 'table-panel';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

import { api } from '@api';
import type { OrganizationSearch } from '@api/modules/organizations';
import { Organization, User } from '@models';
import { alert } from '@services/alert';
import { auth } from '@auth';
import { Root } from '@store';
import { isString } from '@tools/type-guards';

declare module '@vuex/core' {
  export interface Getters {
    'organizations/findById': Organizations['findById'];
  }

  export interface CommitMap {
    'organizations/SET': Organizations['SET'];
    'organizations/ADD': Organizations['ADD'];
    'organizations/UPDATE': Organizations['UPDATE'];
    'organizations/DELETE': Organizations['DELETE'];
    'organizations/CLEAR': Organizations['CLEAR'];
  }

  export interface DispatchMap {
    'organizations/list': Organizations['list'];
    'organizations/get': Organizations['get'];
    'organizations/create': Organizations['create'];
    'organizations/update': Organizations['update'];
    'organizations/giftSeats': Organizations['giftSeats'];
    'organizations/addSeats': Organizations['addSeats'];
    'organizations/removeSeats': Organizations['removeSeats'];
    'organizations/updateBalance': Organizations['updateBalance'];
    'organizations/updateLogo': Organizations['updateLogo'];
    'organizations/removeUser': Organizations['removeUser'];
    'organizations/updateUserLicenses': Organizations['updateUserLicenses'];
    'organizations/updateCertificationPermissions': Organizations['updateCertificationPermissions'];
    'organizations/del': Organizations['del'];
  }
}

@Module({ namespaced: true })
export class Organizations
  extends VuexModule<Organizations.State, Root.State>
  implements Organizations.State
{
  items: Organization[] = [];
  lastEvaluatedKey: ZephyrWeb.Pagination.PlacementKey | null = null;
  allResultsLoaded = false;

  /** ... */
  get findById() {
    return (id: string) => find(this.items, { id });
  }

  //#region Mutations

  /**
   * ...
   */
  @Mutation
  SET(options: Organizations.SetInstitutionsMutationOptions) {
    this.items = options.data;
  }

  /**
   * ...
   */
  @Mutation
  SET_LAST_EVALUATED_KEY(options: {
    lastEvaluatedKey: ZephyrWeb.Pagination.PlacementKey | null;
  }) {
    this.lastEvaluatedKey = options.lastEvaluatedKey;
  }

  /**
   * ...
   */
  @Mutation
  ADD(options: Organizations.AddMutationOptions) {
    const items = [...this.items];

    const i = findIndex(items, { id: options.id });

    if (i !== -1) {
      items[i] = options;
    } else {
      items.push(options);
    }

    this.items = items;
  }

  /**
   * ...
   */
  @Mutation
  UPDATE(options: Organizations.UpdateMutationOptions) {
    const items = [...this.items];

    const i = findIndex(items, { id: options.organizationId });

    if (i <= -1) {
      throw new Error(
        `Institution with ID ${options.organizationId} | Name ${options.name} not found.`,
      );
    }

    for (const key in options) items[i][key] = options[key];

    this.items = items;
  }

  /**
   * ...
   */
  @Mutation
  DELETE(options: Organizations.DeleteMutationOptions) {
    const index = findIndex(this.items, { id: options.organizationId });

    if (index === -1) {
      throw new Error(
        `Institution with ID ${options.organizationId} not found.`,
      );
    }

    this.items = this.items.filter((_, i) => i !== index);
  }

  /**
   * ...
   */
  @Mutation
  CLEAR() {
    this.items = [];
  }

  //#endregion Mutations

  //#region Actions

  /**
   * ...
   */
  @Action
  async loadPage(options: TablePanel.LoadPageOptions<Organization>) {
    const searchOptions = {
      limit: 25,
    } as OrganizationSearch.Options;

    if (isString(options.filter.contains)) {
      searchOptions.organizationId = options.filter.contains;
      searchOptions.name = options.filter.contains;
    }

    let data: Organization[];

    if (this.lastEvaluatedKey && !options.clearPrevious) {
      searchOptions.startKey = this.lastEvaluatedKey;
      data = [...this.items];
    } else {
      data = [];
    }

    const res = await api.organizations.search(searchOptions);

    data.push(...res.items);

    this.context.commit('SET', { data });
    this.context.commit('SET_LAST_EVALUATED_KEY', {
      lastEvaluatedKey: res.lastEvaluatedKey,
    });

    return res;
  }

  /**
   * List organizations.
   */
  @Action
  async list() {
    let data: Organization[] = [];

    data = await api.organizations.list();

    this.context.commit('SET', { data });

    return this.items;
  }

  /**
   * Get organization.
   */
  @Action
  async get(options: Organizations.GetActionOptions) {
    const data = await api.organizations.get(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * Create organization.
   */
  @Action
  async create(options: Organizations.CreateActionOptions) {
    const data = await api.organizations.create(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * Update organization.
   */
  @Action
  async update(options: Organizations.UpdateActionOptions) {
    try {
      await api.organizations.update(options);
    } catch (err) {
      alert.error(err);
      throw err;
    }

    return await (this.context.dispatch('get', {
      organizationId: options.organizationId,
      admin: auth.isActiveRole(9),
    }) as ReturnType<this['get']>);
  }

  /**
   * Gift organization Seats.
   */
  @Action
  async giftSeats(options: Organizations.AddSeatsActionOptions) {
    await api.organizations.giftSeats(options);

    return await (this.context.dispatch('get', {
      organizationId: options.organizationId,
      admin: auth.isActiveRole(9),
    }) as ReturnType<this['get']>);
  }

  /**
   * Add organization seats.
   */
  @Action
  async addSeats(options: Organizations.AddSeatsActionOptions) {
    await api.organizations.addSeats(options);

    return await (this.context.dispatch('get', {
      organizationId: options.organizationId,
      admin: auth.isActiveRole(9),
    }) as ReturnType<this['get']>);
  }

  /**
   * Remove organization seats.
   */
  @Action
  async removeSeats(options: Organizations.RemoveSeatsActionOptions) {
    await api.organizations.removeSeats(options);

    return await (this.context.dispatch('get', {
      organizationId: options.organizationId,
      admin: auth.isActiveRole(9),
    }) as ReturnType<this['get']>);
  }

  /**
   * Update organization balance.
   *
   * @param options ...
   * @return ...
   */
  @Action
  async updateBalance(options: Organizations.CreditActionOptions) {
    await api.organizations.update(options);

    return await (this.context.dispatch('get', {
      organizationId: options.organizationId,
      admin: auth.isActiveRole(9),
    }) as ReturnType<this['get']>);
  }

  /**
   * Update organization logo.
   */
  @Action
  async updateLogo(options: Organizations.UpdateLogoActionOptions) {
    await api.organizations.updateLogo(options);

    return await (this.context.dispatch('get', {
      organizationId: options.organizationId,
      admin: auth.isActiveRole(9),
    }) as ReturnType<this['get']>);
  }

  /**
   * Remove user from organization.
   */
  @Action
  async removeUser(options: Organizations.RemoveUserActionOptions) {
    await api.organizations.removeUser(options);

    const { userId } = options;

    const user = this.context.rootGetters['users/findById'](
      userId,
    ) as User | null;

    if (!user) {
      return this.context.dispatch('users/list', null, { root: true });
    }

    this.context.commit('users/DELETE', { users: [userId] }, { root: true });
  }

  @Action
  async updateCertificationPermissions(
    options: Organizations.UpdateCertificationPermissions,
  ) {
    await api.organizations.updateCertificationPermissions(options);
    const { roles } = await api.users.getByOrganization({
      userId: options.userId,
      organizationId: options.organizationId,
    });

    this.context.commit(
      'users/UPDATE',
      { id: options.userId, roles },
      { root: true },
    );
  }

  /**
   * Update user licenses
   */
  @Action async updateUserLicenses(
    options: Organizations.UpdateUserLicensesOptions,
  ) {
    await api.organizations.updateUserLicenses(options);
  }

  /**
   * Delete institution.
   */
  @Action
  async del(options: Organizations.DeleteActionOptions) {
    await api.organizations.del(options);

    this.context.commit('DELETE', options);
  }

  //#endregion Actions
}

export namespace Organizations {
  /** ... */
  export interface State {
    items: Organization[];
  }

  /** ... */
  export interface SetInstitutionsMutationOptions {
    data: Organization[];
  }

  /** ... */
  export type AddMutationOptions = Organization;
  /** ... */
  export type UpdateMutationOptions = api.organizations.UpdateOptions;
  /** ... */
  export type DeleteMutationOptions = api.organizations.DeleteOptions;
  /** ... */
  export type GetActionOptions = api.organizations.GetOptions;
  /** ... */
  export type CreateActionOptions = api.organizations.CreateOptions;
  /** ... */
  export type UpdateActionOptions = api.organizations.UpdateOptions;
  /** ... */
  export type CreditActionOptions = api.organizations.UpdateOptions;
  /** ... */
  export type AddSeatsActionOptions = api.organizations.AddSeatsOptions;
  /** ... */
  export type RemoveSeatsActionOptions = api.organizations.RemoveSeatsOptions;
  /** ... */
  export type UpdateLogoActionOptions = api.organizations.UpdateLogoOptions;
  /** ... */
  export type RemoveUserActionOptions = api.organizations.RemoveUserOptions;
  /** ... */
  export type UpdateUserLicensesOptions =
    api.organizations.UpdateUserLicensesOptions;
  /** ... */
  export type UpdateCertificationPermissions =
    api.organizations.UpdateCertificationPermissions;
  /** ... */
  export type DeleteActionOptions = api.organizations.DeleteOptions;
}

export default Organizations;
